<template>
  <div class="encryption">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :extraParams="extraParams"
    >
      <template #searchSlot>
        <v-input
          label="名称"
          clearable
          @change="$refs.list.search()"
          v-model="searchParam.title"
        />
      </template>
      <template #operateSlot="{ row }">
        <v-button
          text="附件下载"
          type="text"
          @click="downloadFile(row)"
        ></v-button>
      </template>
    </v-list>
  </div>
</template>

<script>
import { totalSecurityUrl } from "./api.js";
import { downloadHelper } from "@/utils/func";
export default {
  name: "encryption",
  data() {
    return {
      wordsOptions: [],
      searchParam: {
        title: null,
      },
      headers: [
        {
          prop: "code",
          label: "编码",
        },
        {
          prop: "title",
          label: "名称",
        },
        {
          prop: "content",
          label: "描述",
        },
      ],
      extraParams: {
        type: 12,
      },
      tableUrl: totalSecurityUrl,
    };
  },
  mounted() {
    this.getReasons();
  },
  methods: {
    batchSend(id) {
      console.log(id, "+++");
      let ids = id.ids.join(",");
      this.deleteWord(ids);
    },
    // 下载附件
    downloadFile(row) {
      let filePath = "/static/pdf/国密算法概述.pdf";
      if (filePath.substring(0, 1) === "/") {
        window.open(
          `${window.location.origin}${window.location.pathname.substr(
            0,
            window.location.pathname.lastIndexOf("/")
          )}${filePath}`
        );
      } else {
        window.open(filePath);
      }
    },
    getReasons() {
      //   let params = {
      //     tenantId: this.$store.state.app.userInfo.tenantId,
      //   };
      //   this.$axios.get(`${reasonsListUrl}`, params).then((res) => {
      //     if (res.code === 200) {
      //       if (res.data && res.data.sensitiveWordReasonList) {
      //         res.data.sensitiveWordReasonList.forEach((ele) => {
      //           let obj = {};
      //           obj.label = ele.dictKey;
      //           obj.value = ele.dictValue;
      //           this.wordsOptions.push(obj);
      //         });
      //       }
      //     }
      //   });
    },
    deleteWord(ids) {},
    toAdd() {
      this.$router.push({
        name: "addSensitiveWords",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "accountAdd",
        query: { id: data.id, communityId: data.communityId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.encryption {
  box-sizing: border-box;
  height: 100%;
}
</style>
