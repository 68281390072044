//过滤原因列表
const reasonsListUrl = `/gateway/blade-system/sensitiveword/getInitInfo`;
//敏感词管理列表
const sensitiveWordsListUrl = `/gateway/blade-system/sensitiveword/getList`;
//删除敏感词
const deleteWordsUrl = `/gateway/blade-system/sensitiveword/removeWords`;
//新增敏感词
const addWordsUrl = `/gateway/blade-system/sensitiveword/addWords`;
//批量导入
const manyAddWordsUrl = `/gateway/blade-system/sensitiveword/importWords`;
//导出
const outputWordsUrl = `/gateway/blade-system/sensitiveword/exportWords`;

//加密，脱敏
const totalSecurityUrl = `/gateway/hc-portal/security/pageList`;
//风控统计合计
const getRiskControlInfo = `/gateway/hc-portal/openInfoSts/riskStsInfo`;
//风控统计合计
const riskInfoStsListByDay = `/gateway/hc-portal/openInfoSts/riskInfoStsListByDay`;
export {
  reasonsListUrl,
  sensitiveWordsListUrl,
  deleteWordsUrl,
  addWordsUrl,
  manyAddWordsUrl,
  outputWordsUrl,
  totalSecurityUrl,
  getRiskControlInfo,
  riskInfoStsListByDay
};
